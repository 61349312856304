import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_CONFIG_OV
// const ovbaseUrl = process.env.VUE_APP_MICROSERVICE_API_ORDENES_VENTA

export default class OrdenService {
  crear (payload) {
    return http.post(`${baseUrl}/apartados`, payload, {
      headers: {
        loading: true
      }
    })
  }

  actualizar (payload, id) {
    return http.put(`${baseUrl}/apartados/${id}`, payload, {
      headers: {
        loading: true
      }
    })
  }

  listar (params) {
    return http.get(`${baseUrl}/apartados`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  find (id) {
    return http.get(`${baseUrl}/apartados/find/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  cancelar (id) {
    return http.put(`${baseUrl}/apartados/cancelar/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  eliminar (id) {
    return http.delete(`${baseUrl}/apartados/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  getOv (ov) {
    return http.get(`${baseUrl}/apartados/search-pendientes/${ov}`, {
      headers: {
        loading: true
      }
    })
  }

  getEntradasMercancia () {
    return http.get(`${baseUrl}/apartados/search-entradas`, {
      headers: {
        loading: true
      }
    })
  }

  buscarMedicamentosByEntrada (params) {
    return http.get(`${baseUrl}/apartados/search-entradas-detalle`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  buscarMedicamentosEnStock (params) {
    return http.get(`${baseUrl}/apartados/search-stock`, {
      params,
      headers: {
        loading: true
      }
    })
  }
}
